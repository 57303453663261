export default [
  //{
  //  header: 'Dashboards',
  //  icon: 'HomeIcon',
  //  children: [
  //    {
  //      title: 'eCommerce',
  //      route: 'dashboard-ecommerce',
  //      icon: 'ShoppingCartIcon',
  //    },
  //    {
  //      title: 'Analytics',
  //      route: 'dashboard-analytics',
  //      icon: 'ActivityIcon',
  //    },
  //  ],
  //  },
    {
        title: 'Dashboard',
        route: 'dashboard',
        icon: 'HomeIcon',
    },

    {
        title: 'Projects',
        route: 'projects',
        icon: 'SettingsIcon',
    },

    {
        title: 'Contacts',
        route: 'contacts',
        icon: 'UsersIcon',
    },

    {
        title: 'Send Emails',
        route: 'sendemails',
        icon: 'MailIcon',
    },

    {
        title: 'Settings',
        route: 'smtpconfigurations',
        icon: 'SettingsIcon',
    },

    {
        title: 'Payment Methods',
        route: 'paymentmethods',
        icon: 'SettingsIcon',
    },

    {
        title: 'Training',
        route: 'training',
        icon: 'TvIcon',
    },

    {
        title: 'Support',
        route: 'support',
        icon: 'SettingsIcon',
    },
]
